<template>
  <div>
    <v-tabs v-model="tab" show-arrows>
      <v-tab
        v-for="item in quotas"
        :key="item.id"
        @click="setCurrentQuota(item)"
      >
        {{ item.name }}
      </v-tab>
    </v-tabs>
    <router-view :quotasList="quotas"></router-view>
  </div>
</template>

<script>
import quotaListMixin from "@/mixins/quotasList.js";
export default {
  name: "DocumentRouterView",
  mixins: [quotaListMixin],
  data() {
    return {
      tab: null,
    };
  },
  watch: {
    quotas: {
      immediate: true,
      handler(newVal) {
        if (newVal.length > 0 && !this.$route.params.quotaId) {
          this.$router.replace({
            name: "DocumentListOfQuota",
            params: { quotaId: newVal[0].id },
          });
          this.$store.commit("quota/SET_CURRENT_QUOTA", this.quotas[0]);
        }
      },
    },
  },
  methods: {
    setCurrentQuota(payload) {
      this.$store.commit("quota/SET_CURRENT_QUOTA", payload);
      this.$router.push({
        params: { quotaId: payload.id },
      });
    },
  },
};
</script>